<i18n>
  {
    "en": {
      "title": "Mobile Topup",
      "enterNumber": "Enter Mobile Number",
      "serviceProvider": "Service Provider",
      "amount": "Amount (Rs.)"
    },
    "np": {
       "title": "मोबाइल टपअप",
       "enterNumber": "मोबाइल नम्बर राख्नुहोस्",
       "serviceProvider": "सेवा प्रदायक",
       "amount": "रकम (रु.)"
     }
  }
</i18n>
<template lang="pug">
	.container
		div(v-if='paymentModal')
			pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
		form(@submit.prevent="confirmTopup" ref="formRef")
			.wallet-form-box.card
				.card-body
					router-link( to="/wallet" class="btn btn-close")
						i(class='fa fa-times')
					.row
						.col-sm-9.col-lg-6
							h4 {{$t("title")}}
							transition(name='fade')
								.form-group(v-if='message')
									.show-message {{message}}

							.form-group
								label {{ $t("enterNumber") }}
								.input-group.mb-3
									.input-group-prepend
										.input-group-text +977
									input(
										type="tel"
										pattern="[0-9]+"
										name="number"
										v-model="topup.number"
										class="form-control"
										v-on:keyup="onMobileNumberChange"
										:maxlength="topup.allowedLength"
										value
										required
										autocomplete="off"
										:disabled="loading")
							transition(name="fade" mode="out-in")
								div(v-if='validmob' key='1')
									div
										.form-group
											label {{ $t("serviceProvider") }}
											select(class="form-control" v-model="topup.serviceObject")
												option(
													v-for="available in feasibleServices"
													:key="available._id"
													:value="available") {{available.name}}
										.form-group
											label {{ $t("amount") }}
											select.form-control.mb-2(
												v-if="topup.serviceObject.denomination.allowed"
												v-model="topup.amount"
												required)
												option(v-for="allowed in topup.serviceObject.denomination.allowed"
													:key="allowed"
													:value="allowed") {{allowed}}
											div.p-3.bg-light(v-if ="topup.serviceObject.denomination.allowed")
												button.badge.btn-sm.ml-1.p-1( tabindex='-1' type='button'
														v-for='allowed in topup.serviceObject.denomination.allowed' :class="topup.amount === allowed?'badge-danger':'badge-light'"
														@click='topup.amount = allowed') {{allowed}}
											input.form-control(
												v-else
												:placeholder="topup.serviceObject.denomination.min + '-'+ topup.serviceObject.denomination.max"
												type="number"
												v-model="topup.amount"
												:min="topup.serviceObject.denomination.min"
												:max="topup.serviceObject.denomination.max"
												required)
											div(class="text text-danger" v-if="validationError") {{validationError}}
							transition(name="fade" mode='out-in')
								div(v-if="validmob && !loading")
									show-bank(:bankClicked="confirmbank" :banks="banks")
			loading-spinner(:loading= "loading")
</template>

<script>
import { validateNumberWithService } from "../../helpers/general";
import PinConfirm from "@/components/PinConfirm.vue";
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import api from "@/helpers/api";
import payment from "@/mixins/payment";
import axios from "@/axios";
export default {
  name: "topup",
  components: { PinConfirm, ShowBank, LoadingSpinner },
  computed: {
    previewDetails() {
      return {
        "Phone Number": this.topup.number,
        "Total Amount to Pay": "Rs. " + this.topup.amount,
      };
    },
  },
  mixins: [payment],
  data() {
    return {
      topup: {
        number: "",
        allowedLength: 10,
        service: "",
        amount: "",
      },
      validmob: false,
      topupServices: "",
      feasibleServices: "",
      banks: "",
      loading: true,
      validationError: "",
      message: "",
      alert: "success",
    };
  },
  methods: {
    verifyPin(verified) {
      this.topup.pin = verified;
      this.pinVerification(this.confirmTopup, verified);
    },
    /**
     * @param{*} bank - Confirm bank.
     */
    confirmbank(bank) {
      this.topup.bank = bank;
    },
    confirmTopup() {
      if (this.pinVerified) {
        this.loading = true;
        this.topup.service = this.topup.serviceObject.shortName;
        axios
          .post(
            `api/v4/${
              this.topup.service.includes("POSTPAID") ? "post_paid" : "mobile_topup"
            }/payment`,
            this.topup
          )
          .then((res) => {
            if (res.data) {
              this.pinVerified = false;
              this.topup.amount = "";
              if (res.data.status === 1) this.$store.dispatch("UPDATE_BALANCE");
              this.message = res.data.message;
              this.loading = false;
            }
          });
      } else {
        this.paymentModal = true;
      }
    },
    onMobileNumberChange() {
      this.message = "";
      const invalidNumber = /^(00)/.test(this.topup.number);
      if (invalidNumber) {
        this.message = "This is not a number";
        this.alert = "danger";
        this.validmob = false;
        return;
      }
      const isPhone = /^(0)/.test(this.topup.number);
      if (isPhone) {
        this.topup.allowedLength = 9;
      } else {
        this.topup.allowedLength = 10;
      }
      if (this.topup.number.length == 10 || (isPhone && this.topup.number.length == 9)) {
        this.message = "";
        let res = validateNumberWithService(this.topup.number, this.topupServices);
        if (res) {
          this.topup.serviceObject = res.service;
          this.feasibleServices = res.services;
        }
        if (res && this.topup.serviceObject) {
          this.validmob = true;
        } else {
          this.validmob = false;
          this.message = "This number is not supported yet.";
          this.alert = "danger";
        }
      } else {
        this.validmob = false;
      }
    },
  },
  async created() {
    this.topupServices = await api.servicesByCategorySlug("MOBILE_TOPUP");
    this.banks = await api.banks();
    this.loading = false;
  },
};
</script>
